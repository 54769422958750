// Login form.
import React from "react";
import { ethers } from "ethers";
import { FormattedMessage } from "react-intl";

import CheckBox from "../widgets/checkbox.jsx";
import VisiblePassword from "../widgets/visible-password.jsx";

export default class LoginView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: props.login,
      password: "",
      hostName: props.serverAddress,
      saveToken: props.persist,
      toSign: "正在载入...",
      toSignHash: "...",
      signature: "...",
      address: "...",
      rpcEndpoint: "",
    };
    this.handleLoginChange = this.handleLoginChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleToggleSaveToken = this.handleToggleSaveToken.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // get chainid, head block number, head block hash from polygon
  async componentDidMount() {
    // fetch rpc config
    await fetch("/umd/tinode.conf.tiny.json")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ rpcEndpoint: data.rpc_endpoint });
      });

    const provider = new ethers.providers.JsonRpcProvider(this.state.rpcEndpoint);
    let network = await provider.getNetwork();
    let blockNumber = await provider.getBlockNumber();
    let headBlock = await provider.getBlock(blockNumber);

    this.setState({
      chainId: network.chainId,
      blockNumber: blockNumber,
      blockHash: headBlock.hash,
      toSign: `chainId:${network.chainId}:blockNumber:${blockNumber}:blockHash:${headBlock.hash}`,
    });
  }

  handleSign = async (e) => {
    e.preventDefault();

    if (!window.ethereum) {
      alert("No metamask installed");
      return;
    }

    await window.ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(this.state.toSign);
    const recoveredAddress = ethers.utils.verifyMessage(this.state.toSign, signature)
    this.setState({
      signature: signature,
      address: recoveredAddress,
    });
  };

  handleLoginChange(e) {
    this.setState({ login: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleToggleSaveToken() {
    this.props.onPersistenceChange(!this.state.saveToken);
    this.setState({ saveToken: !this.state.saveToken });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.onLogin(
      this.state.login.trim(),
      this.state.password.trim(),
      this.state.toSign,
      this.state.signature,
    );
  }

  render() {
    let submitClasses = "primary";
    if (this.props.disabled) {
      submitClasses += " disabled";
    }
    let pStyle = {
      wordBreak: "break-all",
    };

    return (
      <form id="login-form" onSubmit={this.handleSubmit}>
        <FormattedMessage
          id="login_prompt"
          defaultMessage="Login"
          description="Placeholer for username/login"
        >
          {(login_prompt) => (
            <input
              type="text"
              id="inputLogin"
              placeholder={login_prompt}
              autoComplete="username"
              autoCorrect="off"
              autoCapitalize="none"
              value={this.state.login}
              onChange={this.handleLoginChange}
              required
              autoFocus
            />
          )}
        </FormattedMessage>
        <div className="panel-form-row">
          <p style={pStyle}>
            <b>待签消息:</b>
            <br></br>
            {this.state.toSign}
          </p>
        </div>
        <div className="panel-form-row">
          <p style={pStyle}>
            <b>签名:</b>
            <br></br>
            {this.state.signature}
          </p>
        </div>
        <div className="panel-form-row">
          <p style={pStyle}>
            <b>地址:</b>
            <br></br>
            {this.state.address}
          </p>
        </div>
        <hr></hr>
        <div className="dialog-buttons">
          <button
            className={submitClasses}
            disabled={this.state.signButtonDisabled}
            onClick={this.handleSign}
          >
            <FormattedMessage
              id="button_connect_metamask"
              defaultMessage="Sign"
              description="Sign the message with metamask"
            />
          </button>
        </div>
        <div className="panel-form-row">
          <CheckBox
            id="save-token"
            name="save-token"
            checked={this.state.saveToken}
            onChange={this.handleToggleSaveToken}
          />
          <label htmlFor="save-token">
            &nbsp;
            <FormattedMessage
              id="stay_logged_in"
              defaultMessage="Stay logged in"
              description="Label for a checkbox"
            />
          </label>
        </div>
        <div className="dialog-buttons">
          <button className={submitClasses} type="submit">
            <FormattedMessage
              id="button_sign_in"
              defaultMessage="Sign in"
              description="Button [Sign In]"
            />
          </button>
        </div>
      </form>
    );
  }
}
/* END Login */
