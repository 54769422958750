// Account registration.
import React from "react";
import { ethers } from "ethers";
import { FormattedMessage } from "react-intl";

import AvatarCrop from "../widgets/avatar-crop.jsx";
import AvatarUpload from "../widgets/avatar-upload.jsx";
import CheckBox from "../widgets/checkbox.jsx";
import VisiblePassword from "../widgets/visible-password.jsx";

import LocalStorageUtil from "../lib/local-storage.js";
import {
  imageScaled,
  blobToBase64,
  makeImageUrl,
} from "../lib/blob-helpers.js";
import { theCard } from "../lib/utils.js";

import {
  AVATAR_SIZE,
  MAX_AVATAR_BYTES,
  MAX_EXTERN_ATTACHMENT_SIZE,
  MAX_TITLE_LENGTH,
} from "../config.js";
import { string } from "prop-types";

export default class CreateAccountView extends React.PureComponent {
  constructor(props) {
    super(props);

    let signButtonDisabled = true;
    if (window.ethereum) {
      signButtonDisabled = false;
    }

    this.state = {
      login: "",
      password: "",
      email: "",
      fn: "", // full/formatted name
      imageUrl: null,
      uploadUrl: null,
      newAvatar: null,
      newAvatarMime: null,
      errorCleared: false,
      buttonDisabled: false,
      saveToken: LocalStorageUtil.getObject("keep-logged-in"),
      chainId: "...",
      blockNumber: "...",
      blockHash: "...",
      toSign: "...",
      toSignHash: "...",
      signature: "...",
      address: "...",
      tokenId: "",
      signButtonDisabled: signButtonDisabled,
      rpcEndpoint: "",
    };

    this.handleLoginChange = this.handleLoginChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleFnChange = this.handleFnChange.bind(this);
    this.handleTokenIdChange = this.handleTokenIdChange.bind(this);
    this.handleImageChanged = this.handleImageChanged.bind(this);
    this.handleToggleSaveToken = this.handleToggleSaveToken.bind(this);
    this.handleAvatarCropped = this.handleAvatarCropped.bind(this);
    this.handleAvatarCropCancel = this.handleAvatarCropCancel.bind(this);
    this.uploadAvatar = this.uploadAvatar.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // get chainid, head block number, head block hash from polygon
  async componentDidMount() {
    // fetch rpc config
    await fetch("/umd/tinode.conf.tiny.json")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ rpcEndpoint: data.rpc_endpoint });
      });

    const provider = new ethers.providers.JsonRpcProvider(this.state.rpcEndpoint);
    let network = await provider.getNetwork();
    let blockNumber = await provider.getBlockNumber();
    let headBlock = await provider.getBlock(blockNumber);
    let toSign = `chainId:${network.chainId}:blockNumber:${blockNumber}:blockHash:${headBlock.hash}`;

    this.setState({
      chainId: network.chainId,
      blockNumber: blockNumber,
      blockHash: headBlock.hash,
      toSign: toSign,
      toSignHash: ethers.utils.hashMessage(toSign),
    });
  }

  handleSign = async (e) => {
    e.preventDefault();

    if (!window.ethereum) {
      alert("No metamask installed");
      return;
    }

    await window.ethereum.request({ method: "eth_requestAccounts" });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(this.state.toSign);
    const recoveredAddress = ethers.utils.verifyMessage(this.state.toSign, signature)

    this.setState({
      signature: signature,
      address: recoveredAddress,
    });
  };

  handleLoginChange(e) {
    this.setState({ login: e.target.value });
  }

  handlePasswordChange(password) {
    this.setState({ password: password });
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleFnChange(e) {
    this.setState({ fn: e.target.value });
  }

  handleTokenIdChange(e) {
    this.setState({ tokenId: e.target.value });
  }

  handleImageChanged(mime, img) {
    this.setState({ newAvatar: img, newAvatarMime: mime });
  }

  handleToggleSaveToken() {
    LocalStorageUtil.setObject("keep-logged-in", !this.state.saveToken);
    this.setState({ saveToken: !this.state.saveToken });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ errorCleared: false });
    this.props.onCreateAccount(
      this.state.login.trim(),
      this.state.password.trim(),
      theCard(
        this.state.fn.trim().substring(0, MAX_TITLE_LENGTH),
        this.state.uploadUrl
      ),
      { meth: "email", val: this.state.email },
      undefined,
      this.state.toSign,
      this.state.signature,
      this.state.tokenId
    );
  }

  // AvatarCropView calls this method when the user has cropped the image.
  handleAvatarCropped(mime, blob, width, height) {
    const url = blob ? URL.createObjectURL(blob) : null;
    this.setState({ avatar: url, newAvatar: null, newAvatarMime: null });
    if (blob) {
      this.uploadAvatar(mime, blob, width, height);
    }
  }

  handleAvatarCropCancel() {
    this.setState({ newAvatar: null, newAvatarMime: null });
  }

  // Utility method for converting cropped avatar blob to bytes for sending inband or
  // for uploading it to the server out of band.
  uploadAvatar(mime, blob, width, height) {
    const readyToUpload = (image) => {
      let { mime, blob } = image;
      this.setState({
        imageUrl: URL.createObjectURL(blob),
        buttonDisabled: true,
      });
      if (blob.size > MAX_AVATAR_BYTES) {
        // Too large to send inband - uploading out of band and sending as a link.
        const uploader = this.props.tinode.getLargeFileHelper();
        uploader
          .upload(blob, "newacc")
          .then((url) => this.setState({ uploadUrl: url }))
          .catch((err) => this.props.onError(err.message, "err"))
          .finally((_) => this.setState({ buttonDisabled: false }));
      } else {
        // Convert blob to base64-encoded bits.
        blobToBase64(blob)
          .then((b64) =>
            this.setState({
              uploadUrl: makeImageUrl({ data: b64.bits, type: mime }),
            })
          )
          .finally((_) => this.setState({ buttonDisabled: false }));
      }
    };

    if (width > AVATAR_SIZE || height > AVATAR_SIZE || width != height) {
      // Avatar is not square or too large even after cropping. Shrink it and make square.
      imageScaled(
        blob,
        AVATAR_SIZE,
        AVATAR_SIZE,
        MAX_EXTERN_ATTACHMENT_SIZE,
        true
      )
        .then((scaled) => readyToUpload(scaled))
        .catch((err) => this.props.onError(err.message, "err"));
    } else {
      readyToUpload({ mime: mime, blob: blob, width: width, height: height });
    }
  }

  render() {
    if (this.state.newAvatar) {
      return (
        <AvatarCrop
          avatar={this.state.newAvatar}
          mime={this.state.newAvatarMime}
          onSubmit={this.handleAvatarCropped}
          onCancel={this.handleAvatarCropCancel}
          onError={this.props.onError}
        />
      );
    }

    let submitClasses = "primary";
    if (this.props.disabled) {
      submitClasses += " disabled";
    }
    let pStyle = {
      wordBreak: "break-all",
    };

    return (
      <form className="panel-form-column" onSubmit={this.handleSubmit}>
        <div className="panel-form-row">
          <div className="panel-form-column">
            <FormattedMessage
              id="login_prompt"
              defaultMessage="Login"
              description="Placeholer for username/login"
            >
              {(login_prompt) => (
                <input
                  type="text"
                  placeholder={login_prompt}
                  autoComplete="user-name"
                  value={this.state.login}
                  onChange={this.handleLoginChange}
                  required
                  autoFocus
                />
              )}
            </FormattedMessage>
          </div>
          <AvatarUpload
            tinode={this.props.tinode}
            avatar={this.state.imageUrl}
            onImageUpdated={this.handleImageChanged}
            onError={this.props.onError}
          />
        </div>
        <div className="panel-form-row">
          <FormattedMessage
            id="full_name_prompt"
            defaultMessage="Full name, e.g. John Doe"
            description="Input placeholder for person's full name"
          >
            {(full_name_prompt) => (
              <input
                type="text"
                placeholder={full_name_prompt}
                autoComplete="name"
                value={this.state.fn}
                onChange={this.handleFnChange}
                required
              />
            )}
          </FormattedMessage>
        </div>
        <div className="panel-form-row">
          <FormattedMessage
            id="token_id"
            defaultMessage="Token ID"
            description="Input placeholder for NFT token ID"
          >
            {(token_id) => (
              <input
                type="text"
                placeholder={token_id}
                autoComplete=""
                value={this.state.tokenId}
                onChange={this.handleTokenIdChange}
                required
              />
            )}
          </FormattedMessage>
        </div>
        <div className="panel-form-row">
          <FormattedMessage
            id="email_prompt"
            defaultMessage="Email, e.g. jdoe@example.com"
            description="Input placeholder for email entry"
          >
            {(email_prompt) => (
              <input
                type="email"
                placeholder={email_prompt}
                autoComplete="email"
                value={this.state.email}
                onChange={this.handleEmailChange}
                required
              />
            )}
          </FormattedMessage>
        </div>
        <div className="panel-form-row">
          <p style={pStyle}>
            <b>消息:</b>
            <br></br>
            {this.state.toSign}
          </p>
        </div>
        <div className="panel-form-row">
          <p style={pStyle}>
            <b>消息签名:</b>
            <br></br>
            {this.state.signature}
          </p>
        </div>
        <div className="panel-form-row">
          <p style={pStyle}>
            <b>区块链地址:</b>
            <br></br>
            {this.state.address}
          </p>
        </div>
        <hr></hr>
        <div className="dialog-buttons">
          <button
            className={submitClasses}
            disabled={this.state.signButtonDisabled}
            onClick={this.handleSign}
          >
            <FormattedMessage
              id="button_connect_metamask"
              defaultMessage="Sign"
              description="Sign the message with metamask"
            />
          </button>
        </div>
        <div className="panel-form-row">
          <CheckBox
            id="save-token"
            name="save-token"
            checked={this.state.saveToken}
            onChange={this.handleToggleSaveToken}
          />
          <FormattedMessage
            id="stay_logged_in"
            defaultMessage="Stay logged in"
            description="Label for a checkbox"
          >
            {(stay_logged_in) => (
              <label htmlFor="save-token">&nbsp;{stay_logged_in}</label>
            )}
          </FormattedMessage>
        </div>
        <div className="dialog-buttons">
          <button
            className={submitClasses}
            type="submit"
            disabled={this.state.buttonDisabled}
          >
            <FormattedMessage
              id="button_sign_up"
              defaultMessage="Sign up"
              description="Create account button [Sign Up]"
            />
          </button>
        </div>
      </form>
    );
  }
}
